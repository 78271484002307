import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Colors, Button, Grid, Row, Caption1, Card, CoverRatio, Badge, Icon, Divider } from '@class101/ui';
import { NoHiddenStyleCol } from './Col';
import { imageSrc } from './interface';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "card"
    }}>{`Card`}</h1>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Card} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Grid>\n  <Row>\n    <NoHiddenStyleCol lg={6}>\n      <Card\n        title=\"카드 타이틀 두 줄 까지만 허용합니다 두 줄 넘어가면 말 줄임표가 생깁니다 카드 타이틀 두 줄 까지만 허용합니다 두 줄 넘어가면 말 줄임표가 생깁니다\"\n        coverImage={imageSrc}\n        extraTop={\n          <Caption1 fontWeight={600} color={Colors.gray900}>\n            사진영상 ・ 정멜멜\n          </Caption1>\n        }\n        extraBottom={\n          <div style={{ marginLeft: \'-4px\', lineHeight: \'0\' }}>\n            <Badge\n              icon={<Icon.Heart fillColor={Colors.gray400} />}\n              backgroundColor=\"transparent\"\n              color={Colors.gray400}\n              size=\"sm\"\n            >\n              1874\n            </Badge>{\' \'}\n            <Badge\n              icon={<Icon.Like fillColor={Colors.gray400} />}\n              backgroundColor=\"transparent\"\n              color={Colors.gray400}\n              size=\"sm\"\n            >\n              98%\n            </Badge>\n          </div>\n        }\n      >\n        <div style={{ marginTop: 10, marginBottom: 10 }}>\n          <Divider color=\"#F2F4F5\" />\n        </div>\n        <Badge\n          size=\"lg\"\n          color={Colors.gray600}\n          backgroundColor={Colors.gray000}\n          style={{ marginTop: \'8px\' }}\n        >\n          바로수강가능\n        </Badge>\n      </Card>\n    </NoHiddenStyleCol>\n    <NoHiddenStyleCol lg={6}>\n      <Card\n        title=\"나만의 그림으로 내 삶을 채우다, 강한의 색연필 일러스트\"\n        coverImage={imageSrc}\n      />\n    </NoHiddenStyleCol>\n  </Row>\n</Grid>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Colors,
      Button,
      Grid,
      Row,
      Caption1,
      Card,
      CoverRatio,
      Badge,
      Icon,
      Divider,
      NoHiddenStyleCol,
      imageSrc,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Grid mdxType="Grid">
    <Row mdxType="Row">
      <NoHiddenStyleCol lg={6} mdxType="NoHiddenStyleCol">
        <Card title="카드 타이틀 두 줄 까지만 허용합니다 두 줄 넘어가면 말 줄임표가 생깁니다 카드 타이틀 두 줄 까지만 허용합니다 두 줄 넘어가면 말 줄임표가 생깁니다" coverImage={imageSrc} extraTop={<Caption1 fontWeight={600} color={Colors.gray900} mdxType="Caption1">사진영상 ・ 정멜멜</Caption1>} extraBottom={<div style={{
              marginLeft: '-4px',
              lineHeight: '0'
            }}>
              <Badge icon={<Icon.Heart fillColor={Colors.gray400} />} backgroundColor="transparent" color={Colors.gray400} size="sm" mdxType="Badge">
                1874
              </Badge>
              {' '} 
              <Badge icon={<Icon.Like fillColor={Colors.gray400} />} backgroundColor="transparent" color={Colors.gray400} size="sm" mdxType="Badge">
                98%
              </Badge>
            </div>} mdxType="Card">
          <div style={{
                marginTop: 10,
                marginBottom: 10
              }}>
            <Divider color="#F2F4F5" mdxType="Divider" />
          </div>
          <Badge size="lg" color={Colors.gray600} backgroundColor={Colors.gray000} style={{
                marginTop: '8px'
              }} mdxType="Badge">바로수강가능</Badge>
        </Card>
      </NoHiddenStyleCol>
      <NoHiddenStyleCol lg={6} mdxType="NoHiddenStyleCol">
        <Card title="나만의 그림으로 내 삶을 채우다, 강한의 색연필 일러스트" coverImage={imageSrc} mdxType="Card" />
      </NoHiddenStyleCol>
    </Row>
  </Grid>
    </Playground>
    <h2 {...{
      "id": "with-image-ratio"
    }}>{`With Image Ratio`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`export enum CoverRatio {
  RATIO_16X9 = 0.5625,
  RATIO_16X10 = 0.625,
  RATIO_4X3 = 0.75,
  RATIO_3X4 = 1.333,
  RATIO_1X1 = 1,
}
`}</code></pre>
    <Playground __position={2} __code={'<Grid>\n  <Row>\n    <NoHiddenStyleCol lg={6}>\n      <Card\n        title=\"아이패드 드로잉\"\n        coverImageRatio={CoverRatio.RATIO_16X9}\n        coverImage={imageSrc}\n      />\n    </NoHiddenStyleCol>\n    <NoHiddenStyleCol lg={6}>\n      <Card\n        title=\"아이패드 드로잉\"\n        coverImageRatio={CoverRatio.RATIO_1X1}\n        coverImage={imageSrc}\n      />\n    </NoHiddenStyleCol>\n  </Row>\n</Grid>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Colors,
      Button,
      Grid,
      Row,
      Caption1,
      Card,
      CoverRatio,
      Badge,
      Icon,
      Divider,
      NoHiddenStyleCol,
      imageSrc,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Grid mdxType="Grid">
    <Row mdxType="Row">
      <NoHiddenStyleCol lg={6} mdxType="NoHiddenStyleCol">
        <Card title="아이패드 드로잉" coverImageRatio={CoverRatio.RATIO_16X9} coverImage={imageSrc} mdxType="Card" />
      </NoHiddenStyleCol>
      <NoHiddenStyleCol lg={6} mdxType="NoHiddenStyleCol">
        <Card title="아이패드 드로잉" coverImageRatio={CoverRatio.RATIO_1X1} coverImage={imageSrc} mdxType="Card" />
      </NoHiddenStyleCol>
    </Row>
  </Grid>
    </Playground>
    <h2 {...{
      "id": "with-image-component"
    }}>{`With Image Component`}</h2>
    <p>{`이미지 비율은 이미지 컴포넌트에서 제어해야 합니다.`}</p>
    <Playground __position={3} __code={'<Grid>\n  <Row>\n    <NoHiddenStyleCol lg={8}>\n      <Card\n        title=\"아이패드 드로잉\"\n        coverImage={\n          <div style={{ overflow: \'hidden\' }}>\n            <img\n              src={imageSrc}\n              style={{ display: \'block\', objectFit: \'cover\' }}\n            />\n          </div>\n        }\n      />\n    </NoHiddenStyleCol>\n  </Row>\n</Grid>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Colors,
      Button,
      Grid,
      Row,
      Caption1,
      Card,
      CoverRatio,
      Badge,
      Icon,
      Divider,
      NoHiddenStyleCol,
      imageSrc,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Grid mdxType="Grid">
    <Row mdxType="Row">
      <NoHiddenStyleCol lg={8} mdxType="NoHiddenStyleCol">
        <Card title="아이패드 드로잉" coverImage={<div style={{
              overflow: 'hidden'
            }}>
              <img src={imageSrc} style={{
                display: 'block',
                objectFit: 'cover'
              }} />
            </div>} mdxType="Card" />
      </NoHiddenStyleCol>
    </Row>
  </Grid>
    </Playground>
    <h2 {...{
      "id": "anchor-card"
    }}>{`Anchor Card`}</h2>
    <Playground __position={4} __code={'<Grid>\n  <Row>\n    <NoHiddenStyleCol lg={6}>\n      <Card title=\"아이패드 드로잉\" coverImage={imageSrc} to={\'/\'} external>\n        <Button style={{ marginTop: \'24px\' }}>Native Anchor</Button>\n      </Card>\n    </NoHiddenStyleCol>\n    <NoHiddenStyleCol lg={6}>\n      <Card title=\"아이패드 드로잉\" coverImage={imageSrc} href={\'/\'} external>\n        <Button style={{ marginTop: \'24px\' }}>\n          Link of React Router DOM\n        </Button>\n      </Card>\n    </NoHiddenStyleCol>\n  </Row>\n</Grid>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Colors,
      Button,
      Grid,
      Row,
      Caption1,
      Card,
      CoverRatio,
      Badge,
      Icon,
      Divider,
      NoHiddenStyleCol,
      imageSrc,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Grid mdxType="Grid">
    <Row mdxType="Row">
      <NoHiddenStyleCol lg={6} mdxType="NoHiddenStyleCol">
        <Card title="아이패드 드로잉" coverImage={imageSrc} to={'/'} external mdxType="Card">
          <Button style={{
                marginTop: '24px'
              }} mdxType="Button">Native Anchor</Button>
        </Card>
      </NoHiddenStyleCol>
      <NoHiddenStyleCol lg={6} mdxType="NoHiddenStyleCol">
        <Card title="아이패드 드로잉" coverImage={imageSrc} href={'/'} external mdxType="Card">
          <Button style={{
                marginTop: '24px'
              }} mdxType="Button">Link of React Router DOM</Button>
        </Card>
      </NoHiddenStyleCol>
    </Row>
  </Grid>
    </Playground>
    <h2 {...{
      "id": "extra-component"
    }}>{`Extra Component`}</h2>
    <Playground __position={5} __code={'<Grid>\n  <Row>\n    <NoHiddenStyleCol lg={6}>\n      <Card\n        title=\"아이패드 드로잉\"\n        coverImage={imageSrc}\n        to={\'/\'}\n        exernal\n        extraTop={\n          <div style={{ lineHeight: \'0\' }}>\n            <Caption1 style={{ display: \'inline-block\' }} fontWeight={\'Bold\'}>\n              바로 수강 가능\n            </Caption1>\n            <Caption1\n              style={{ display: \'inline-block\', marginLeft: \'11px\' }}\n              fontWeight={\'Bold\'}\n              color={Colors.orange700}\n            >\n              40% 할인\n            </Caption1>\n          </div>\n        }\n      />\n    </NoHiddenStyleCol>\n    <NoHiddenStyleCol lg={6}>\n      <Card\n        title=\"아이패드 드로잉\"\n        coverImage={imageSrc}\n        to={\'/\'}\n        exernal\n        extraBottom={\n          <Caption1 color={Colors.gray400}>10월 12일 ~ 10월 24일</Caption1>\n        }\n      />\n    </NoHiddenStyleCol>\n  </Row>\n</Grid>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Colors,
      Button,
      Grid,
      Row,
      Caption1,
      Card,
      CoverRatio,
      Badge,
      Icon,
      Divider,
      NoHiddenStyleCol,
      imageSrc,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Grid mdxType="Grid">
    <Row mdxType="Row">
      <NoHiddenStyleCol lg={6} mdxType="NoHiddenStyleCol">
        <Card title="아이패드 드로잉" coverImage={imageSrc} to={'/'} exernal extraTop={<div style={{
              lineHeight: '0'
            }}>
              <Caption1 style={{
                display: 'inline-block'
              }} fontWeight={'Bold'} mdxType="Caption1">
                바로 수강 가능
              </Caption1>
              <Caption1 style={{
                display: 'inline-block',
                marginLeft: '11px'
              }} fontWeight={'Bold'} color={Colors.orange700} mdxType="Caption1">
                40% 할인
              </Caption1>
            </div>} mdxType="Card" />
      </NoHiddenStyleCol>
      <NoHiddenStyleCol lg={6} mdxType="NoHiddenStyleCol">
        <Card title="아이패드 드로잉" coverImage={imageSrc} to={'/'} exernal extraBottom={<Caption1 color={Colors.gray400} mdxType="Caption1">10월 12일 ~ 10월 24일</Caption1>} mdxType="Card" />
      </NoHiddenStyleCol>
    </Row>
  </Grid>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      